
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexelfn_45_yXyAWz4EVuM1iE2naWo9mnh50e25AudVLFE7YMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/index.vue?macro=true";
import { default as index60em0O4d0EkAhV_AeuZ0mMaEId7xYLCeuExnBiRXVyUMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startNFSo12hkqpzLbIrEPppMyHXj4ZNir05aoeviH1Yzru8Meta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start9qPa1Z5W4Nekz8sJXCsd_451Tdh7WXZSYMOme3RlVnr5QMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutjhBWLLve1WX7UsEH_WxYM3KTYsYocy3k_9Y0sOX1wmUMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as komnFHc2XPnSI0_45rnjrQdzGceiRevjpYCmsftehL7zl_oMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmation4i309yor__45lPVb44VFxCJ3ST_459szI_45m7iIsufZCoodUMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationjFSi5oAPUVcMZZXzTCtXFjiMikVfpjVhL2Y1tY59C84Meta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerPY3bwZCY159AVT5w5HGV9MxCi5LeozC1qjYc_5x0kvQMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerkffnwuLzDdM52VQqQq7klyx3Vc5QzVppDDAMDyyuHMQMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentmRWd5DbLLY1nygwlNs86qpMheOETzOYsWMnDfttyXv4Meta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeOn8zBBvgO1lCrzSrdI6xZ2BPDPqKki667NedMfpgfWwMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentzzM2wpPscjIHsz3aloe7sg1iwaUBXZ3P3Qt5mxtcNRkMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta } from "/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue?macro=true";
export default [
  {
    name: indexelfn_45_yXyAWz4EVuM1iE2naWo9mnh50e25AudVLFE7YMeta?.name ?? "index",
    path: indexelfn_45_yXyAWz4EVuM1iE2naWo9mnh50e25AudVLFE7YMeta?.path ?? "/",
    props: indexelfn_45_yXyAWz4EVuM1iE2naWo9mnh50e25AudVLFE7YMeta?.props ?? false,
    meta: indexelfn_45_yXyAWz4EVuM1iE2naWo9mnh50e25AudVLFE7YMeta || {},
    alias: indexelfn_45_yXyAWz4EVuM1iE2naWo9mnh50e25AudVLFE7YMeta?.alias || [],
    redirect: indexelfn_45_yXyAWz4EVuM1iE2naWo9mnh50e25AudVLFE7YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/index.vue")
  },
  {
    name: index60em0O4d0EkAhV_AeuZ0mMaEId7xYLCeuExnBiRXVyUMeta?.name ?? "lang",
    path: index60em0O4d0EkAhV_AeuZ0mMaEId7xYLCeuExnBiRXVyUMeta?.path ?? "/:lang?",
    props: index60em0O4d0EkAhV_AeuZ0mMaEId7xYLCeuExnBiRXVyUMeta?.props ?? false,
    meta: index60em0O4d0EkAhV_AeuZ0mMaEId7xYLCeuExnBiRXVyUMeta || {},
    alias: index60em0O4d0EkAhV_AeuZ0mMaEId7xYLCeuExnBiRXVyUMeta?.alias || [],
    redirect: index60em0O4d0EkAhV_AeuZ0mMaEId7xYLCeuExnBiRXVyUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startNFSo12hkqpzLbIrEPppMyHXj4ZNir05aoeviH1Yzru8Meta?.name ?? "lang-voucher-start",
    path: startNFSo12hkqpzLbIrEPppMyHXj4ZNir05aoeviH1Yzru8Meta?.path ?? "/:lang?/voucher/start",
    props: startNFSo12hkqpzLbIrEPppMyHXj4ZNir05aoeviH1Yzru8Meta?.props ?? false,
    meta: startNFSo12hkqpzLbIrEPppMyHXj4ZNir05aoeviH1Yzru8Meta || {},
    alias: startNFSo12hkqpzLbIrEPppMyHXj4ZNir05aoeviH1Yzru8Meta?.alias || [],
    redirect: startNFSo12hkqpzLbIrEPppMyHXj4ZNir05aoeviH1Yzru8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start9qPa1Z5W4Nekz8sJXCsd_451Tdh7WXZSYMOme3RlVnr5QMeta?.name ?? "lang-purchase-start",
    path: start9qPa1Z5W4Nekz8sJXCsd_451Tdh7WXZSYMOme3RlVnr5QMeta?.path ?? "/:lang?/purchase/start",
    props: start9qPa1Z5W4Nekz8sJXCsd_451Tdh7WXZSYMOme3RlVnr5QMeta?.props ?? false,
    meta: start9qPa1Z5W4Nekz8sJXCsd_451Tdh7WXZSYMOme3RlVnr5QMeta || {},
    alias: start9qPa1Z5W4Nekz8sJXCsd_451Tdh7WXZSYMOme3RlVnr5QMeta?.alias || [],
    redirect: start9qPa1Z5W4Nekz8sJXCsd_451Tdh7WXZSYMOme3RlVnr5QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutjhBWLLve1WX7UsEH_WxYM3KTYsYocy3k_9Y0sOX1wmUMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutjhBWLLve1WX7UsEH_WxYM3KTYsYocy3k_9Y0sOX1wmUMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutjhBWLLve1WX7UsEH_WxYM3KTYsYocy3k_9Y0sOX1wmUMeta?.props ?? false,
    meta: checkoutjhBWLLve1WX7UsEH_WxYM3KTYsYocy3k_9Y0sOX1wmUMeta || {},
    alias: checkoutjhBWLLve1WX7UsEH_WxYM3KTYsYocy3k_9Y0sOX1wmUMeta?.alias || [],
    redirect: checkoutjhBWLLve1WX7UsEH_WxYM3KTYsYocy3k_9Y0sOX1wmUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: komnFHc2XPnSI0_45rnjrQdzGceiRevjpYCmsftehL7zl_oMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: komnFHc2XPnSI0_45rnjrQdzGceiRevjpYCmsftehL7zl_oMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: komnFHc2XPnSI0_45rnjrQdzGceiRevjpYCmsftehL7zl_oMeta?.props ?? false,
    meta: komnFHc2XPnSI0_45rnjrQdzGceiRevjpYCmsftehL7zl_oMeta || {},
    alias: komnFHc2XPnSI0_45rnjrQdzGceiRevjpYCmsftehL7zl_oMeta?.alias || [],
    redirect: komnFHc2XPnSI0_45rnjrQdzGceiRevjpYCmsftehL7zl_oMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmation4i309yor__45lPVb44VFxCJ3ST_459szI_45m7iIsufZCoodUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmation4i309yor__45lPVb44VFxCJ3ST_459szI_45m7iIsufZCoodUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmation4i309yor__45lPVb44VFxCJ3ST_459szI_45m7iIsufZCoodUMeta?.props ?? false,
    meta: confirmation4i309yor__45lPVb44VFxCJ3ST_459szI_45m7iIsufZCoodUMeta || {},
    alias: confirmation4i309yor__45lPVb44VFxCJ3ST_459szI_45m7iIsufZCoodUMeta?.alias || [],
    redirect: confirmation4i309yor__45lPVb44VFxCJ3ST_459szI_45m7iIsufZCoodUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationjFSi5oAPUVcMZZXzTCtXFjiMikVfpjVhL2Y1tY59C84Meta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationjFSi5oAPUVcMZZXzTCtXFjiMikVfpjVhL2Y1tY59C84Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationjFSi5oAPUVcMZZXzTCtXFjiMikVfpjVhL2Y1tY59C84Meta?.props ?? false,
    meta: confirmationjFSi5oAPUVcMZZXzTCtXFjiMikVfpjVhL2Y1tY59C84Meta || {},
    alias: confirmationjFSi5oAPUVcMZZXzTCtXFjiMikVfpjVhL2Y1tY59C84Meta?.alias || [],
    redirect: confirmationjFSi5oAPUVcMZZXzTCtXFjiMikVfpjVhL2Y1tY59C84Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerPY3bwZCY159AVT5w5HGV9MxCi5LeozC1qjYc_5x0kvQMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerPY3bwZCY159AVT5w5HGV9MxCi5LeozC1qjYc_5x0kvQMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerPY3bwZCY159AVT5w5HGV9MxCi5LeozC1qjYc_5x0kvQMeta?.props ?? false,
    meta: buyerPY3bwZCY159AVT5w5HGV9MxCi5LeozC1qjYc_5x0kvQMeta || {},
    alias: buyerPY3bwZCY159AVT5w5HGV9MxCi5LeozC1qjYc_5x0kvQMeta?.alias || [],
    redirect: buyerPY3bwZCY159AVT5w5HGV9MxCi5LeozC1qjYc_5x0kvQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerkffnwuLzDdM52VQqQq7klyx3Vc5QzVppDDAMDyyuHMQMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerkffnwuLzDdM52VQqQq7klyx3Vc5QzVppDDAMDyyuHMQMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerkffnwuLzDdM52VQqQq7klyx3Vc5QzVppDDAMDyyuHMQMeta?.props ?? false,
    meta: buyerkffnwuLzDdM52VQqQq7klyx3Vc5QzVppDDAMDyyuHMQMeta || {},
    alias: buyerkffnwuLzDdM52VQqQq7klyx3Vc5QzVppDDAMDyyuHMQMeta?.alias || [],
    redirect: buyerkffnwuLzDdM52VQqQq7klyx3Vc5QzVppDDAMDyyuHMQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentmRWd5DbLLY1nygwlNs86qpMheOETzOYsWMnDfttyXv4Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentmRWd5DbLLY1nygwlNs86qpMheOETzOYsWMnDfttyXv4Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentmRWd5DbLLY1nygwlNs86qpMheOETzOYsWMnDfttyXv4Meta?.props ?? false,
    meta: paymentmRWd5DbLLY1nygwlNs86qpMheOETzOYsWMnDfttyXv4Meta || {},
    alias: paymentmRWd5DbLLY1nygwlNs86qpMheOETzOYsWMnDfttyXv4Meta?.alias || [],
    redirect: paymentmRWd5DbLLY1nygwlNs86qpMheOETzOYsWMnDfttyXv4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeOn8zBBvgO1lCrzSrdI6xZ2BPDPqKki667NedMfpgfWwMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeOn8zBBvgO1lCrzSrdI6xZ2BPDPqKki667NedMfpgfWwMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeOn8zBBvgO1lCrzSrdI6xZ2BPDPqKki667NedMfpgfWwMeta?.props ?? false,
    meta: challengeOn8zBBvgO1lCrzSrdI6xZ2BPDPqKki667NedMfpgfWwMeta || {},
    alias: challengeOn8zBBvgO1lCrzSrdI6xZ2BPDPqKki667NedMfpgfWwMeta?.alias || [],
    redirect: challengeOn8zBBvgO1lCrzSrdI6xZ2BPDPqKki667NedMfpgfWwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentzzM2wpPscjIHsz3aloe7sg1iwaUBXZ3P3Qt5mxtcNRkMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentzzM2wpPscjIHsz3aloe7sg1iwaUBXZ3P3Qt5mxtcNRkMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentzzM2wpPscjIHsz3aloe7sg1iwaUBXZ3P3Qt5mxtcNRkMeta?.props ?? false,
    meta: paymentzzM2wpPscjIHsz3aloe7sg1iwaUBXZ3P3Qt5mxtcNRkMeta || {},
    alias: paymentzzM2wpPscjIHsz3aloe7sg1iwaUBXZ3P3Qt5mxtcNRkMeta?.alias || [],
    redirect: paymentzzM2wpPscjIHsz3aloe7sg1iwaUBXZ3P3Qt5mxtcNRkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 0",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-com-cera-para-biquini-brasil/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-com-cera-para-biquini-no-brasil/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 2",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/bracos-de-depilacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 3",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-vip-massagem-50-min-rececao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 4",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/especifico-localizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 5",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/amai-spa/esfoliacao-corporal-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 6",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/parceiro-formador/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 7",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/voucher-mensal-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 8",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/propina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 9",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/pack-de-acido-hialuronico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 10",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/leon/eurostars-via-de-la-plata/circuito-spa-massagem-com-canas-de-bambu/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 11",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/cartao-de-oferta-para-empresas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 12",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/spa-tour-acesso-50-acesso-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 13",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-massagem-30-bilhete-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 14",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/experiencia-de-bem-estar-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 15",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/assinatura-de-luxo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 16",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/vila-real/boticas-hotel-art-spa/escapadinha-relax-massagem-spa-estadia-pequeno-almoco/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 17",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guadalajara/spa-castilla-termal-brihuega/duche-vichy-a-quatro-maos-com-peeling-de-mel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 18",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guadalajara/spa-castilla-termal-brihuega/reflexologia-tailandesa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 19",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-9-tratamento-corporal-e-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 20",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/spa-attica-21-villalba/tratamento-corporal-nutritivo-com-mel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 21",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/spa-attica-21-villalba/tratamento-corporal-nutritivo-com-melremovido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 22",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/1-dia-de-spa-diamante-com-massagem-60-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 23",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/pack-rose-cerveja-de-hidromel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 24",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-antioxidante-revitalizante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 25",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/especial-dia-dos-namorados-o-melhor-presente-e-partilha-lo-contigo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 26",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/bio-massagem-relaxante-25-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 27",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/promocao-especial-para-os-amantes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 28",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/pestanas-permanentes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 29",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/experiencia-bahiaspa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 30",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/express-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 31",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/kit-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 32",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/spa-tapas-2-pax/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 33",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-pernas-e-pes-frescos-circuito-termal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 34",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/spa-brunch-no3/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 35",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/circuito-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 36",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cantabria/spa-castilla-termal-solares/relaxamento-total-quatro-maos-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 37",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spa-sercotel-odeon-hotel/circuito-relax-spa-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 38",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/leon/eurostars-via-de-la-plata/circuito-de-spa-90-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 39",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/oasis-of-love-pack-casal-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 40",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/pack-oasis-do-amor-dia-dos-namorados-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 41",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-ayurvedica-30-massagem-da-terra-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 42",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/pack-ritual-para-casais-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 43",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-albufeira/ritual-pais-e-filhos-circuito-spa-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 44",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/exclusivity-beer-massage-55min-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 45",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guipuzcoa/thalasso-villa-antilla/dia-dos-namorados-de-sonho/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 46",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/remocao-de-pelos-por-diodo-pequena-area/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 47",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-massage-50-experiencia-de-pequeno-almoco-b-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 48",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/massagem-para-futuras-mamas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 49",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-de-6-dias-da-semana-anti-stress/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 50",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/pureza-ritual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 51",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/segredos-do-nilo-90min-spazio-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 52",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spa-sercotel-odeon-hotel/circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 53",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/black-friday-2x1-massagem-30-bilhete-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 54",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/venda-10-de-desconto-1-bilhete-duplo-de-acesso-ao-spa-50-total-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 55",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/om-in-love-especial-do-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 56",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spaalmoco-ou-jantar-2-pessoas-80e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 57",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-tailandesa-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 58",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dor-dia-3-envolvimento-de-lama-mineral-banho-de-sal-25m-de-massagem-de-relaxamento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 59",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/suplemento-embalado-europlayas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 60",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/desmaquilhante-colour-gel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 61",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/pernas-inteiras-biquini-axilas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 62",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/hard-rock-spa-marbella/zona-de-agua/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 63",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-reconfortante-para-as-doencas-linfaticas-do-sangue/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 64",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/este-natal-ofereca-a-prenda-do-bem-estar-2-pax/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 65",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/programa-beauty-platinum-1-pax-higiene-facial-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 66",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/especial-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 67",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spasinho/massagem-para-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 68",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/fitness-2aadd/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 69",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-massage-25-experiencia-gastro-f-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 70",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/ritual-de-cuidados-do-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 71",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/voucher-de-4-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 72",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/dia-de-spa-acompanhante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 73",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/aladinia-massagem-de-75-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 74",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/massagem-bio-muscular-profunda-25-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 75",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-de-desintoxicacao-dia-1-udvartana/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 76",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/propina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 77",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/vip-ritual-de-assinatura-de-luxo-do-serena-spa-e-area-de-bem-estar-privada-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 78",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/por-ser-canario-talasso-3h-e-smoothie/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 79",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guipuzcoa/thalasso-villa-antilla/escapadela-romantica-2-noites/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 80",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/massagem-para-um-sono-tranquilo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 81",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/despacio-beauty-center/tratamento-osteopatico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 82",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-especial-para-desportistas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 83",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/experiencia-de-vitalidade-sessao-de-crossfit-thalassa-90-minutos-de-circuito-de-spa-sessao-de-pressoterapia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 84",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/massagem-calma/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 85",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/maternidade-por-comfort-zone/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 86",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/penteado-de-noiva/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 87",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/massagem-desportiva/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 88",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/meia-perna-e-depilacao-da-virilha/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 89",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/diamante-bem-viver-de-natura-bisse-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 90",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-spa/bloqueio-de-30/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 91",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-3-em-1-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 92",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/diferenca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 93",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/circuito-iberik-sabados-e-feriados-i-admissao-2030-horas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 94",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-do-duche-de-jato/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 95",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/o-spa-pure-salt-port-adriano/massagem-sueca-30-min-four-hands/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 96",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/especial-casais-vip-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 97",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/massagem-cervicodorsal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 98",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cava/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 99",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/calmo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 100",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/fuerteventura/spa-sensations-iberostar-las-gaviotas/sentidos-noite-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 101",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/limpeza-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 102",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/andulacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 103",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-de-aloe-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 104",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/viagem-a-rota-das-delicias-individual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 105",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/massagem-ao-por-do-sol-no-egito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 106",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/black-friday-2x1-massagem-30-bilhete-spa-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 107",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/bau-de-casal-momento-especial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 108",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/circuito-hotel-alegria/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 109",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spa-sensations-iberostar-bahia-de-palma/massagem-pos-laboral-de-30-min-com-circuito-termal-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 110",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/depilacao-do-peito-masculino/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 111",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/parceiro-formador/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 112",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/mascara-corporal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 113",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/tratamento-capilar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 114",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/beer-spa/massagem-holistica-com-oleo-de-cerveja-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 115",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/spa-balneario/pt/spas/acores/musgo-spa/spa-para-1-adulto-e-1-crianca-7-13-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 116",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/wonderbox-2-pessoas-3990e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 117",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spai-son-armadans/ar-do-mar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 118",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-roca-esmeralda/oferta-flash-massagem-anti-stress-30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 119",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/circuito-spa-ritual-pre-solar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 120",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-do-contorno-dos-olhos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 121",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/ritual-da-orquidea/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 122",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/ritual-de-luxo-bio-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 123",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/bio-massagem-para-criancas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 124",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-anti-stress/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 125",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/hb-black-friday-serenidade-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 126",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/pedicura-prospa-opi-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 127",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-do-peito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 128",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/circuito-spa-massagem-25-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 129",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-alojado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 130",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-de-privilegio-25/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 131",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/spa-familiar-2x1-mais-20-de-desconto-nos-tratamentos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 132",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/barba-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 133",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/radiant-splendour-ritual-c/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 134",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/balayage-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 135",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/wellness-access-myevjf-90-privatizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 136",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/parceiro-mensal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 137",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/hotelbreak-massagem-de-25-minutos-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 138",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/especial-de-natal-serenity-spa-massagem-taca-de-cava-e-caixa-alqvimia-presente-para-si/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 139",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/especial-de-natal-serenity-spa-massagem-taca-de-cava-e-bau-de-alqvimia-presente-para-si/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 140",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/queixo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 141",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/kit-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 142",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/reserva-de-servico-de-ar-condicionado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 143",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/spa-romantico-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 144",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-cadiz/pack-duo-de-cervejas-privadas-para-casais-no-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 145",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-cadiz/cervejaria-privada-duo-pack-dia-dos-namorados-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 146",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/limpeza-facial-profunda-creme-gley-brechs-como-presente/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 147",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/pacote-de-cura-de-hidratacao-para-2-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 148",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-de-mochila-de-hidratacao-de-2-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 149",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/60-drenagem-linfatica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 150",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/higueron-spa/pacote-de-experiencias-sensoriais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 151",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/exclusivity-massage-completar-55/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 152",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/circuito-de-spa-para-adultos-de-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 153",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-atrapalo-75-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 154",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/pacote-especial-para-casais-spa-massagem-45/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 155",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/after-seven-spa-gourmet/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 156",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/senior-banheira-de-hidromassagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 157",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/oporto/neya-day-spa-porto/invicta-express-duo-para-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 158",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-8-m-50-wrap/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 159",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/treino-funcional-1-sessao-1-sessao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 160",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/tempo-de-cocoon-por-voya/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 161",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/higiene-facial-basica-para-duas-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 162",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/lavar-e-secar-o-cabelo-comprido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 163",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-com-pindas-de-ervas-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 164",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-wellness-50-experiencia-de-pequeno-almoco-de-segunda-a-sexta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 165",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/recolha-da-noiva/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 166",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/remedio-por-zona-de-conforto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 167",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-do-peeling-facial-cura-do-peeling-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 168",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-do-peeling-facial-cura-do-peeling-facial-cura-do-peeling-facial-cura-do-peeling-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 169",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-wellness-urh-ciutat-de-mataro/spa-e-almoco/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 170",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/especial-casal-circuito-alegria-15-min-massagem-servico-de-cha-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 171",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/circuito-spa-e-beleza-grupo6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 172",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/stones-paradise-70-spa-para-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 173",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/especial-casais-tratamento-privado-da-cerveja-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 174",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/inibir-o-lifting-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 175",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/pureza-ritual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 176",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/tratamento-facial-calmante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 177",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/momento-spa-e-massagem-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 178",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/tratamento-facial-hydra-sothys/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 179",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/m-production-dia-de-spa-1-acompanhante-194e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 180",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/spa-relax-com-60-min-de-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 181",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-2-peeling-corporal-massagem-personalizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 182",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/hidraderme/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 183",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/neya-day-spa-lisboa/banheira-de-hidromassagem-neya-bamboo-massage/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 184",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/all-hb-ht-suplemento-spa-pack-50-l-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 185",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/desintoxicacao-capilar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 186",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-vip-massagem-30-min-rececao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 187",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/pacote-de-amor-cintilante-para-casais-no-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 188",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/destaques-bicolores-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 189",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/novo-lancamento-da-golden-tan/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 190",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-wellness-urh-ciutat-de-mataro/spa-e-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 191",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/ritual-hidraluronico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 192",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/momento-spa-e-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 193",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/massagem-shiatsu-local/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 194",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/depilacao-das-sobrancelhas-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 195",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/lemax-wellness-club-wellington/circuito-spa-60-massagem-45-em-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 196",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/beer-spa/massagem-de-pindas-com-extrato-de-cerveja-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 197",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/combo-4-sessoes-ama-o-teu-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 198",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tarragona/spa-la-hacienda/circuito-ohtels-15-min-massagem-servico-de-cha-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 199",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/por-do-sol-vista-para-o-mar-x2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 200",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/especial-she-rainha-do-egito-por-alqvimia-presente-bilhete-duplo-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 201",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/massagem-25-min-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 202",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-augas-santas-balneario-golf/circuito-termico-bilhete-de-crianca-tfa-vermelho/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 203",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-augas-santas-balneario-golf/circuito-termal-bilhete-de-entrada-para-criancas-tfa-vermelho/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 204",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-por-crioterapia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 205",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/especial-casais-circuito-alegria-55-min-massagem-servico-de-cha-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 206",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-massagem-50-bilhete-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 207",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/wellness-experiencia-matinal-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 208",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/pense-na-sua-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 209",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/massagem-de-relaxamento-das-canarias-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 210",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/beer-spa-madrid/pacote-de-amor-cintilante-para-casais-no-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 211",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/cuide-da-sua-pele-este-verao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 212",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-wellness-natal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 213",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-2-m-c-f-m-pes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 214",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/desinfecao-das-cabinas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 215",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/combo-2-sessoes-ama-o-teu-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 216",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-60-min-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 217",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/massagem-de-tecidos-profundos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 218",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/momento-spa-e-massagem-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 219",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/especial-natal-love-para-2-pessoas-spa-massagem-taca-de-cava-e-bau-de-alqvimia-presente-para-si/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 220",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/piscina-para-adultos-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 221",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/spa-sensations-lanzarote-park/corpo-inteiro-para-homens/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 222",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/ritual-al-andalus/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 223",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massagem-com-cerveja-45min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 224",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/agencias-wellness-area-90-privatizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 225",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/heal-restore-50-spa-para-casal-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 226",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/tinta-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 227",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-de-emagrecimento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 228",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-para-emagrecer/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 229",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/noite-romantica-no-be-aloe-com-massagem-e-jantar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 230",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/spa-privado-elixir-facial-repair-scens/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 231",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-cadiz/10-sessoes-do-circuito-beer-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 232",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/parceiro-premium/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 233",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/ht-massagem-30-experiencia-gastro/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 234",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/ayurveda-pinda-sweda/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 235",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/at-spa-50-cava-v-d/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 236",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/depilacao-por-diodos-zona-intermedia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 237",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/massagem-15-min-accomodated-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 238",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/massagem-relaxante-e-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 239",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/massagem-relaxante-gratuita-15/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 240",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/circuito-termico-120min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 241",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-do-atlas-marrocos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 242",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/massagem-relaxante-20-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 243",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/terapia-da-madeira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 244",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/oasis-love-25/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 245",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-aladinia-25-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 246",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/propina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 247",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/depilacao-por-diodo-corpo-inteiro/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 248",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-programa-de-suite-50-min-1-pessoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 249",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/balneario-vichy-catalan/circuito-termico-hotel-cliente/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 250",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-wellness-urh-ciutat-de-mataro/spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 251",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/pack-hidr-oxigenio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 252",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/especial-do-dia-dos-namorados-om-spa-in-love/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 253",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/ritual-de-natal-de-sonho/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 254",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tarragona/spa-vilaromana-salou/massagem-de-essencias-naturais-chocolate-25-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 255",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/circuito-spa-adulto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 256",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/aladinia-40-minutos-de-massagem-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 257",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-aladinia-40-minutos-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 258",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/noite-bcn-spa-60-cava/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 259",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/piscina-para-adultos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 260",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/spa-buffet-crianca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 261",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-rd-2-pessoas-130e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 262",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/massagem-de-relaxamento-30/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 263",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/massagem-com-banheira-de-hidromassagem-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 264",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-com-pedras-quentes-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 265",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/para-a-melhor-mae/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 266",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/ritual-hidraluronico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 267",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/massagem-de-relaxamento-local/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 268",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/30-massagem-oriental/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 269",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/circuito-spa-privado-60-min-exp/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 270",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/axilas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 271",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-de-natal-exfoliant-with-bruise-and-ficus/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 272",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/circuitos-life-is-beautiful-2-pessoas-3990e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 273",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/meios-bracos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 274",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-atrapalo-50-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 275",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/voucher-de-6-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 276",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/black-friday-2x1-massagem-50-bilhete-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 277",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/maquilhagem-de-noiva/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 278",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/ritual-de-natal-em-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 279",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-pazo-los-escudos/terapia-das-pedras/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 280",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/especial-casal-circuito-alegria-25-min-massagem-servico-de-cha-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 281",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/pack-romantico-casais-especiais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 282",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/experiencia-privada-de-spa-lifting-facial-scens/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 283",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/spa-bodyna-madrid/tratamento-facial-30-massagem-20/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 284",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/spa-familiar-2x1-mais-20-de-desconto-nos-tratamentos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 285",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/talassoterapia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 286",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-expresso-para-dois/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 287",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/dakotabox-spaalmoco-jantar-2-pessoas-7990-euros/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 288",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/ritual-poder-vulcanico-esfoliacao-podal-massagem-com-pedras-quentes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 289",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/pincas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 290",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/reserva-de-servico-de-ar-condicionado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 291",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/design-de-sobrancelhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 292",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/desintoxicacao-tipo-botox-spiruline-boost/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 293",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/bcn-night-15-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 294",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-la-residencia-tarifa/esfoliante-corporal-de-coco-plataformas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 295",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/ritual-de-delicadeza-das-maos-clube-termal-flotarium-20/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 296",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/experiencia-air-bio-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 297",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/circuito-para-duas-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 298",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-com-essencias-naturais-parcial-25/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 299",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/manicure-de-luxo-50-min-sem-verniz/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 300",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/sant-jordi-com-spa-privado-circuito-de-aguas-champanhe-rosa-e-ou-chocolates-para-2-massagens-30/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 301",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/15-minutos-depilacao-das-sobrancelhas-e-dos-labios/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 302",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/circuito-iberik-sabados-e-feriados-i-entrada-1000/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 303",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/massagem-15min-pagamento-direto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 304",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/piscina-exterior/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 305",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/pack-massagem-50-spa-gastronomia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 306",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-escapadela-romantica-1-pessoa-30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 307",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-1-dia-menu-canto-da-natureza-x-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 308",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/massagem-expresso-acesso-ao-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 309",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/circuitos-a-vida-e-bela-2-pessoas-4990e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 310",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/agua/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 311",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/pernas-inteiras-seios-ou-axilas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 312",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/festa-de-beleza-ellas-spa-privado-com-open-bar-de-cava-e-caixa-de-chocolates/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 313",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/manicure-infinito-opi-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 314",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/asturias/spa-nature/agosto-retiro-de-saude-e-bem-estar-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 315",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/saco-de-esfoliacao-xl/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 316",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/ritual-bio-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 317",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/spa-familiar-com-pequeno-almoco-buffet-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 318",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/reserva-de-servico-de-ar-condicionado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 319",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/good-morning-kids-experience-pequeno-almoco-buffet-90-minutos-de-circuito-de-spa-dos-5-aos-11-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 320",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/felicidade-com-os-amigos-spa-privado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 321",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/pacote-de-sensores/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 322",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spa-bodyna-maricel/30-massagem-taoista-com-pedras-quentes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 323",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/especifico-localizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 324",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-1-aloe-vera/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 325",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/flotarium-40/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 326",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/ritual-de-pindas-energeticas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 327",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/kit-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 328",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/pack-hidr-oxigenio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 329",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/ritual-radiance-c/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 330",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/menorca/spa-faustino-gran/anakasumi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 331",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-augas-santas-balneario-golf/circuito-termico-entrada-s-d-f-recp/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 332",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/massagem-ao-por-do-sol-no-egito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 333",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-tratamento-facial-kobido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 334",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/ritual-musgo-bliss-esfoliacao-massagem-com-oleos-essenciais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 335",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/bio-chi-rosto-e-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 336",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cortar-e-pentear-cabelos-muito-compridos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 337",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-massagem-25-m-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 338",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-a-cabeca-e-aos-pes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 339",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-tandem-a-cabeca-e-aos-pes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 340",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/spa-sensations-lanzarote-park/peeling-envolvimento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 341",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/extensao-do-servico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 342",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/propina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 343",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/ht-wellness-experiencia-gastro-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 344",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/jaen/thermas-palacio-de-ubeda/ritual-de-renascimento-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 345",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/descoloracao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 346",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/tribunal-de-cavalheiros-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 347",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-lifting-e-anti-envelhecimento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 348",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/assinatura-de-diamante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 349",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/circuito-das-criancas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 350",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/ritual-pausa-para-dois-especial-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 351",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bcn-noite-pequeno-almoco/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 352",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/ritual-de-bem-estar-do-casal-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 353",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/radiofrequencia-capilar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 354",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/embalagem-anti-manchas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 355",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/santuari-urban-spa/tratamento-de-cafetaria/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 356",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/momento-spa-ritual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 357",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-completa-das-pernas-e-da-zona-do-biquini/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 358",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/tinta-para-pestanas-e-sobrancelhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 359",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/viagem-a-rota-das-delicias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 360",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/circuito-spa-gastronomia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 361",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/b-calm-relax-30-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 362",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/massagem-individual-10-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 363",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spamassagem-40e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 364",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/tintura-de-cabelo-comprido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 365",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/spa-attica-21-villalba/beleza-spa-tratamento-40-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 366",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/viagem-a-rainha-do-egito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 367",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/oasis-love40/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 368",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/sweet-waiting-ritual-thermal-club-flotarium-20/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 369",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-cranio-facial-indiana-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 370",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-craniofacial-indiana-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 371",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spasinho/circuito-spa-massagem-craniofacial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 372",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/propina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 373",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tarragona/spa-la-hacienda/circuito-ohtels-25-min-massagem-servico-de-cha-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 374",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/destaques-do-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 375",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/fogueiras-de-san-juan-circuito-beer-spa-25-min-de-massagem-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 376",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/senator-granada-spa-hotel/2-circuitos-spa-para-adultos-pack-senzia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 377",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/all-at-al-hb-ht-pequeno-almoco-buffet/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 378",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/massagem-55-min-accomodated-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 379",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/massagem-55-min-alojamento-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 380",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/santuari-urban-spa/limpeza-facial-profunda/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 381",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/spa-privado-pequeno-almoco-massagem-para-1-pessoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 382",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/infralabial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 383",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-bodyna-manicure-tratamentos-para-maos-e-pes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 384",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/bio-massagem-relaxante-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 385",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/melia-nas-nuvens/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 386",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/bochechas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 387",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/viagem-ao-eden-tropical-individual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 388",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/tintura-para-sobrancelhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 389",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tarragona/beer-spa-salou/grupo-do-circuito-das-termas-da-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 390",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/desintoxicacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 391",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tarragona/spa-vilaromana-salou/ritual-geotermico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 392",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/experiencia-sweet-mornings/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 393",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/circuito-sensacoes-alojamento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 394",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/venda-40-4-bilhetes-duplos-acesso-spa-50-total-8-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 395",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-dorsal-descontraturante-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 396",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-de-natal-para-criancas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 397",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/viagem-ao-eden-tropical/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 398",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/massagem-balinesa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 399",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/depilacao-completa-da-perna/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 400",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-beatriz-toledo-auditorium/jantar-spa-beauty-party/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 401",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guipuzcoa/thalasso-villa-antilla/escapadela-romantica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 402",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/voucher-4-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 403",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/mae-tu-mereces-circuito-de-aguas-massagem-circuito-de-aguas-de-acompanhamento-gratis/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 404",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/treino-pessoal-4-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 405",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/massagem-de-relaxamento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 406",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-cadiz/pacote-de-amor-cintilante-para-casais-no-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 407",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/renovacao-vital-natal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 408",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/embalagem-anti-manchas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 409",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-intensiva/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 410",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/pedicura/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 411",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/asturias/spa-nature/outubro-retiro-de-saude-e-bem-estar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 412",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/ritual-de-bem-estar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 413",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/rejuvenescimento-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 414",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/depilacao-do-labio-superior/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 415",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/aromaterapia-80min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 416",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-massagem-25-experiencia-gastro-f-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 417",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/pedicura-semi-permanente/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 418",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/aluguer-de-fatos-de-banho/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 419",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/depilacao-do-braco-masculino/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 420",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-neuro-sedativa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 421",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valladolid/spa-castilla-termal-monasterio-de-valbuena/shiatsu/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 422",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/saco-de-sanita-relax-xl/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 423",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/massagem-de-30-experiencia-matinal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 424",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-desportiva-parcial-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 425",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-acupunctura-40-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 426",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/massagem-anti-celulite/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 427",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/massagem-anti-celulitica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 428",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/ritual-facial-premium-tratamento-completo-com-25-minutos-de-massagem-anti-envelhecimento-relaxamento-85-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 429",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/viagem-ao-eden-tropical/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 430",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-para-golfistas-70-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 431",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/calmo-peles-delicadas-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 432",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-relaxante-e-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 433",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/circuito-motril-alojamento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 434",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/at-massagem-25f-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 435",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alava/silken-villa-laguardia-hotel/relaxamento-em-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 436",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-relaxante-e-anti-stress/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 437",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-cervejeira-completa-degustacao-de-1-cerveja-especial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 438",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/cocoon-reconnect-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 439",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/massagem-pre-mama/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 440",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-augas-santas-balneario-golf/chocolate-wrap/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 441",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-do-atlas-marrocos-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 442",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/ritual-lulur-acesso-ao-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 443",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/experiencia-romantica-no-dia-dos-namorados-2-pax/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 444",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/ritual-da-rainha-do-egito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 445",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/pack-hidr-oxigenio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 446",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/circuito-de-spa-para-2-natais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 447",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/especial-de-natal-serenity-spa-massagem-taca-de-cava-e-bau-de-alqvimia-presente-para-si/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 448",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/massagem-de-relaxamento-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 449",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-havaiana-lomi-lomi-rececao-60-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 450",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/familia-circuito-de-spa-de-90-minutos-para-criancas-dos-5-aos-11-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 451",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/fim-de-semana-25/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 452",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/caixa-de-petalas-de-violeta-para-um-casal-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 453",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/balneario-vichy-catalan/circuito-termico-infantil-cliente-do-hotel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 454",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guadalajara/spa-castilla-termal-brihuega/massagem-tailandesa-tradicional-55/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 455",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spa-sercotel-odeon-hotel/massagem-anti-stress-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 456",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/signature-spa-beach/ritual-de-serenidade-e-vitalidade-55/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 457",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-experiencia-bodyna-2-pessoas-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 458",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/o-spa-pure-salt-port-adriano/massagem-30-ht-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 459",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/sessao-de-desbloqueio-emocional/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 460",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/clube-desportivo-urbano-acesso-ao-spa-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 461",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/personalizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 462",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/pack-de-acido-hialuronico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 463",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/spa-privado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 464",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/luxo-ritual-luxo-nusa-dua-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 465",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/pedicura-de-luxo-50-min-sem-verniz/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 466",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/casal-ritual-de-tentacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 467",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/circuito-alegria/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 468",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/3-sessoes-de-laser-de-diodo-para-todo-o-corpo-1-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 469",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/reserva-de-servico-de-ar-condicionado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 470",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/semi-updo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 471",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guadalajara/spa-castilla-termal-brihuega/massagem-facial-kobido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 472",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/apenas-bolo-aniversario/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 473",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/penteado-extra-longo-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 474",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-com-algas-marinhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 475",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/envolvimento-com-algas-marinhas-cura/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 476",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-15min-pagamento-direto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 477",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/casal-especial-de-natal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 478",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cantabria/spa-castilla-termal-solares/shiatsu-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 479",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/buffet-de-spa-para-adultos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 480",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/massagem-10min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 481",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/esfoliante-corporal-express/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 482",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/25-min-de-massagem-alojamento-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 483",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-3-banho-de-algas-drenagem-linfatica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 484",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/spa-balneario/pt/spas/acores/musgo-spa/spa-para-2-adultos-e-2-criancas-7-13-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 485",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/maquilhagem-extra-para-unhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 486",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/circuito-spa-90-min-1-adulto-1-crianca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 487",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/tratamento-facial-the-cure/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 488",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/unhas-acrilicas-gel-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 489",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/acesso-ao-spa-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 490",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/viagem-de-massagem-total-voyager/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 491",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-da-mascara-de-algas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 492",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-da-mascara-de-algas-cura-da-mascara-de-algas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 493",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/corte-para-raparigas-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 494",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/unico-spa-finca-serena-mallorca/massagem-de-bem-estar-45/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 495",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/spa-privado-massagem-de-drenagem-bio-detox/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 496",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/bonus-10-sessoes-completas-de-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 497",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/luxor-spa/experiencia-de-casal-banho-aromatico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 498",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-de-exclusividade-parcial-25/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 499",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-sueca-descontratante-completa-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 500",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-anual-seg-qui/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 501",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/maquilhagem-de-dia-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 502",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/circuito-spa-ritual-aftersun/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 503",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/circuito-alegria-grupos-6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 504",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/circuito-da-catalunha/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 505",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/massagem-de-tecidos-profundos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 506",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-think-of-you-40-min-1-pax/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 507",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/mae-tu-mereces-circuito-de-aguas-massagem-circuito-de-aguas-de-acompanhamento-gratis/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 508",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/id-a-105e-2pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 509",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/viagem-a-provenca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 510",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/dedos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 511",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/massagem-com-cataplasma-de-ervas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 512",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/propina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 513",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/voucher-10-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 514",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/experiencia-de-vichy/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 515",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/vip-spa-privado-30-garrafa-de-cava/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 516",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/cartao-presente-premium/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 517",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/menorca/spa-faustino-gran/ritual-botanico-de-menorca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 518",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/especial-natal-serenity-espaco-wellness-massagem-taca-de-cava-e-caixa-de-oferta-alqvimia-presente-para-si/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 519",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/voucher-10-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 520",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-wellness-50-experiencia-gastro-seg-sab/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 521",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/corte-e-modelacao-de-cabelos-medios-lady/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 522",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/corte-e-modelacao-de-cabelo-medio-lady/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 523",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cortar-sem-lavar-caballero/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 524",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/15-minutos-de-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 525",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/asturias/spa-nature/agosto-retiro-de-saude-e-bem-estar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 526",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/spa-para-criancas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 527",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valladolid/spa-castilla-termal-olmedo/shiatsu/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 528",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-das-axilas-e-da-virilha-no-brasil/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 529",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-das-axilas-e-da-linha-do-biquini-no-brasil/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 530",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/circuito-de-spa-privado-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 531",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/massagem-50-min-individual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 532",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/massagem-50-experiencia-matinal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 533",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/massagem-55-min-pacote-aromatico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 534",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/massagem-55-min-pacote-arom/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 535",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/fitness-spa-2-aadd/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 536",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/masssagem-com-aromaterapia-60min-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 537",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/beer-spa/pacote-de-amor-cintilante-para-casais-no-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 538",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/beer-spa/pacote-de-amor-cintilante-para-casal-no-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 539",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/3-sessoes-de-laser-de-diodo-na-zona-media-1-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 540",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/mom-to-be-50-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 541",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-wellbeing-alabriga/oasis-de-serenidade/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 542",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/massagem-gratuita-15min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 543",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/bodyna-experience-50-min-de-massagem-relaxante-com-citrinos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 544",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-de-relaxamento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 545",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-massagem-50-experiencia-gastro-seg-a-qua/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 546",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/ritual-de-delicadeza-das-maos-flotarium-20/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 547",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/sh-villa-gadea/feliz-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 548",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/lomi-lomi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 549",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/hidrofacial-h2o/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 550",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/inner-flow/bbd-spa-flotarium-massagem-duo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 551",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-wellness-urh-ciutat-de-mataro/spa-e-pequeno-almoco/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 552",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/smartbox-spaalmoco-jantar-2-pessoas-7990-euros/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 553",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/viagem-aos-vinhedos-da-toscana/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 554",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/asturias/spa-nature/outubro-retiro-de-saude-e-bem-estar-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 555",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/velas-quentes-da-lagoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 556",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/assinatura-diamante-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 557",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/ayurveda-udvartana/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 558",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/senator-cadiz-spa-hotel/involucro-de-cereja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 559",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/experiencia-de-viagem-privilege/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 560",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-fresca-circuito-termal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 561",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-wellbeing-alabriga/massagem-a-quatro-maos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 562",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-60-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 563",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/citrus-vita-essence/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 564",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/ingles/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 565",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-bahia-del-duque/day-pass-spa-pacote-de-massagem-personalizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 566",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/balneario-vichy-catalan/2x-circuito-termico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 567",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/spa-islantilla/banho-de-espuma/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 568",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/especial-dia-dos-namorados-ritual-do-cupido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 569",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pro-doces-sonhos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 570",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-da-banheira-de-hidromassagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 571",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-de-banho-de-hidromassagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 572",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/massagem-com-oleo-de-chocolate/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 573",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/acesso-a-piscina-para-adultos-90-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 574",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/3-sessoes-de-laser-de-diodo-numa-pequena-area-1-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 575",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/pack-hidratacao-rosto-corpo-presente-cosmetico-ejove/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 576",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/agencias-spa-entrance-50-privatizadas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 577",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/oferta-dia-dos-namorados-massagem-circuito-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 578",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/hard-rock-spa-marbella/zona-de-agua-menu-saudavel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 579",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/bem-estar-experiencia-gastro-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 580",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/circuito-de-spa-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 581",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/circuito-hidrotermal-massagem-domus-aurea/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 582",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/massagem-descontracturante-relaxante-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 583",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-5-ritual-de-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 584",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/3-sessoes-de-laser-de-diodo-em-grande-area-1-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 585",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/tratamento-facial-de-luxo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 586",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/mascara-anti-celulite/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 587",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-com-mascara-anti-celulite/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 588",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/senza-vi-a-cores/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 589",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/massagem-ao-por-do-sol-no-egito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 590",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/cor-da-raiz-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 591",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/pedras-vulcanicas-25m/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 592",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/massagem-personalizada-local/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 593",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-massagem-50-pequeno-almoco-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 594",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/depilacao-das-sobrancelhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 595",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/spa-hotel-hyltor/spa-privado-jantar-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 596",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/entrada-spa-expansao-do-servico-cliente-mcm/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 597",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/cataplasma-ayurveda-royal-thai/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 598",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-dia-1-massagem-udvartana-desintoxicacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 599",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/depilacao-com-cera-para-biquini/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 600",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-pernas-cansadas-30-massagem-energias-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 601",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/sauna-e-jacuzzi-1-pessoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 602",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-oriental-shiatsu/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 603",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/sauna-30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 604",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/tinta-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 605",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/vale-ep-de-10-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 606",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavagem-corte-e-penteado-cabelos-compridos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 607",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/ht-massagem-50-experiencia-matinal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 608",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/experiencia-spa-de-natal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 609",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lleida/balneario-caldes-de-boi/quiromassagem-local/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 610",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/agencias-bilhete-spa-90-privatizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 611",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/agencias-entrada-spa-90-privatizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 612",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/agencias-spa-ticket-90-privatizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 613",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/ingles-completo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 614",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/coloracao-de-pestanas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 615",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-anti-dor-anti-stress-parceria/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 616",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/relaxamento-em-banguecoque-banho-de-pes-jacuzzi-massagem-a-cabeca-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 617",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/combinacao-de-depilacao-sobrancelhas-labio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 618",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/pacote-de-prevencao-vital/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 619",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/reflexologia-podal-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 620",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/circuito-de-spa-groupon/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 621",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-oriental-45-massagem-energias-da-terra-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 622",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-peeling-de-papaia-esfoliacao-corporal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 623",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-peeling-de-papaia-esfoliante-corporal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 624",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/ht-massagem-50-experiencia-gastro/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 625",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/wellness-ritual-massagem-de-relaxamento-20-para-um-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 626",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/entre-as-sobrancelhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 627",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/zen-com-pedras-quentes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 628",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-grand-hotel-mencey/especial-dia-dos-namorados-ritual-de-amor-serenity/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 629",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/spa-massagem-piscina-exterior/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 630",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/aladinia-massagem-de-50-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 631",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/prospa-manicure-opi-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 632",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/piccolo-de-cava/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 633",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-wellness-50-gastro-experience-50-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 634",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-pinda-sweda-india-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 635",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/alimentacao-despedida-de-soltera/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 636",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/reserva-de-servico-de-ar-condicionado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 637",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/circuito-infantil/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 638",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/viagem-a-provenca-tour/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 639",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/grupo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 640",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/massagem-de-relaxamento-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 641",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/massagem-de-aromaterapia-nas-costas-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 642",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/leon/eurostars-via-de-la-plata/circuito-spa-massagem-ayurvedica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 643",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/tudo-at-al-hb-ht-menu-de-jantar-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 644",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massagem-25-min-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 645",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/massagem-50-experiencia-gastro/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 646",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/personal-trainer-em-casal-60min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 647",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/mae-tu-mereces-circuito-de-aguas-massagem-circuito-de-aguas-de-acompanhamento-gratis/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 648",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/programa-beauty-platinum1-pax-massagem-anti-stress-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 649",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/banho-de-espuma/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 650",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/pacote-de-bem-estar-e-relaxamento-2-pax/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 651",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/digital-wellness-escape-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 652",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/valencia-lovers-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 653",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/reuniao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 654",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/sweet-waiting-ritual-flotarium-20/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 655",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/drenagem-linfatica-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 656",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-albufeira/para-ela-circuito-spa-mini-facial-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 657",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/piscina-hidrotermal-cocktail-atrapalo-1-pessoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 658",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/ancient-cocoon-reflexologia-podal-tailandesa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 659",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/hb-massagem-50m-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 660",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/sessao-no-solario-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 661",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/back-soul-acesso-ao-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 662",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/pack-jar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 663",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tarragona/spa-vilaromana-salou/massagem-de-essencia-natural-cerveja-25-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 664",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/senator-cadiz-spa-hotel/voucher-pessoal-10-bilhetes-para-o-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 665",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/remocao-de-gel-de-polimento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 666",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/remocao-do-verniz-de-gel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 667",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/formacao-de-casais-4-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 668",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spa-sensations-iberostar-bahia-de-palma/after-work-duplo-30-min-de-massagem-com-circuito-termal-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 669",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/ginasio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 670",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/al-spa-50cava-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 671",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/just-relax-50-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 672",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/spa-the-flag/especial-de-marco-massagem-aromatica-tratamento-facial-expresso/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 673",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/hard-rock-spa-marbella/zona-aquatica-5-acessos-individuais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 674",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cantabria/balneario-la-hermida/ritual-de-balneoterapia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 675",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/hb-massagem-25m-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 676",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/descamacao-das-costas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 677",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-albufeira/para-ele-circuito-spa-massagem-corporal-mini-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 678",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/pedicura-permanente/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 679",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/massagem-15min-individual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 680",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/massagem-relaxante-20-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 681",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/hot-unique-50-relax-your-mind-20-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 682",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/massagem-com-essencias-naturais-completa-55/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 683",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-20-min-rececao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 684",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valladolid/spa-castilla-termal-olmedo/relaxamento-maximo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 685",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/experiencia-bio-spa-85/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 686",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/just-relax-for-two-50-spa-para-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 687",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/acesso-ao-wellness-area-60-profes-especial-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 688",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/pacote-de-sensores/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 689",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/flotarium-20/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 690",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/suplemento-de-corante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 691",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/soria/spa-castilla-termal-burgo-de-osma/bloqueio-de-cabina-4-h/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 692",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/oasis-detox-25/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 693",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-de-aromaterapia-45-massagens-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 694",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pro-massagem-sueca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 695",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/terapia-por-pressao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 696",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/massagem-anti-stress-na-cabeca-pescoco-e-ombros/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 697",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-balinesa-para-duas-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 698",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/combo-5-sessoes-1-sessao-gratuita-ame-o-seu-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 699",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/tinta/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 700",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/corte-de-franjas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 701",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/circuito-vip-em-sala-privada-casal-tabua-de-charcutaria/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 702",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/massagem-e-esfoliacao-choccolat-acesso-ao-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 703",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/massagem-anti-stress-com-oleos-de-monoi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 704",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/piscina-para-criancas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 705",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/heal-restore-50-spa-para-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 706",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-zahir-essence-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 707",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/immerso-spa/especial-sao-valentim-massagem-de-velas-a-dois-60-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 708",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/ritual-de-esplendor-radiante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 709",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-id-65e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 710",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/embalagem-anti-manchas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 711",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-massagem-25-experiencia-de-pequeno-almoco-seg-sab/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 712",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-cadiz/massagem-15min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 713",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/spa-attica-21-villalba/relaxamento-com-sabor-a-crianca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 714",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/meias-pernas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 715",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/agencia-do-circuito-da-praia-de-granada-5e-5e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 716",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-pequeno-almoco-for-early-risers/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 717",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-lomi-lomi-havaiana-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 718",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/senior-jet-jet/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 719",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/massagem-com-pedras-quentes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 720",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/oasis-detox-40/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 721",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/circuitos-wonderboxalmoco-jantar-2-pessoas-7790e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 722",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/personal-trainer-e-massagem-personalizada-110min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 723",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/parabens-mae-mereces-tudo-vamos-comecar-por-cuidar-de-ti/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 724",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/bracos-de-depilacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 725",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guadalajara/spa-castilla-termal-brihuega/reiki/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 726",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/almoco-circuito-spa-90-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 727",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/saco-de-sanita-relax-xl/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 728",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/remocao-de-semi-unhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 729",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/massagem-15-livresuprimido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 730",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/massagem-de-aromaterapia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 731",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-de-drenagem-linfatica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 732",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/rd-a-65e-1-pessoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 733",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/happy-hour-just-relax-for-two-50-spa-para-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 734",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cantabria/spa-castilla-termal-solares/lomi-juma-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 735",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/massagem-de-aromaterapia-nas-costas-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 736",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/pincar-as-sobrancelhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 737",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/flotarium-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 738",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/massagem-ayurvedica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 739",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/ritual-hidraluronico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 740",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-esfoliantes-corporais-aromaticos-esfoliantes-corporais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 741",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/esfoliacao-corporal-completa-envolvimento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 742",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/massagem-bio-muscular-profunda-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 743",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/arrancar-as-sobrancelhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 744",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/depilacao-das-axilas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 745",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/bio-face-50-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 746",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-do-chocolate-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 747",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/reuniao-30-reuniao-30-reuniao-30-reuniao-30-reuniao-30-reuniao-30-reuniao-30-reuniao-30-reuniao-30-reuniao-30/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 748",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guadalajara/spa-castilla-termal-brihuega/terapia-de-ventosas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 749",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-de-descontratacao-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 750",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/massagem-de-relaxamento-45/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 751",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/especial-casais-vip-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 752",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/removedor-de-maquilhagem-colour-gel-da-opi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 753",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/vila-real/natureness-spa/spa-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 754",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/buffet-de-spa-adulto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 755",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/sonho-de-callao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 756",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/massagem-25-min-alojamento-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 757",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/25-min-de-massagem-alojamento-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 758",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lleida/balneario-caldes-de-boi/hamman-e-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 759",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cantabria/spa-castilla-termal-solares/programa-osteoarticular-3-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 760",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/viana-do-castelo/feelviana-spa/especial-sao-valentim-massagem-ritual-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 761",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/al-massagem-25-f-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 762",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/o-melhor-presente-massagem-anti-stress/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 763",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/depilacao-facial-por-unidade/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 764",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/voucher-6-massagens-tailandesas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 765",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-tailandesa-oriental/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 766",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/voucher-de-10-sessoes-de-circuito-spa-adulto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 767",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-beatriz-toledo-auditorium/noivos-com-pormenores-romanticos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 768",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/circuito-beer-spa-com-catering-de-tapas-frias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 769",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spa-sercotel-odeon-hotel/jacuzzi-privado-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 770",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/voucher-de-4-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 771",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/corte-e-lavagem-de-roupa-para-homem-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 772",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/signature-spa-beach/massagem-de-relaxamento-30min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 773",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/massagem-10min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 774",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/circuito-spa-privado-almoco-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 775",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/all-at-al-gr-suplemento-spa-pack-50-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 776",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cartao-de-oferta-para-empresas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 777",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/tratamento-facial-hidratante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 778",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/acesso-wellness-myevjf-50-nao-privatizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 779",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-desportiva-massagem-energia-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 780",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/reserva-de-servico-de-ar-condicionado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 781",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/massagem-de-pes-pescoco-e-cabeca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 782",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-individual-10-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 783",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-beatriz-toledo-auditorium/circuito-massagem-anti-stress-25-segunda-feira-a-sexta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 784",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-tratamento-kobido-precieuse-tratamentos-faciais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 785",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/acesso-ao-spa-naturista-90-1-copo-de-cava-preco-especial-fnnc/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 786",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/circuito-da-praia-de-granada-grupos-6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 787",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/circuito-de-praia-granada-grupos-6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 788",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/noite-romantica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 789",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/spa-classico-circuito-de-agua-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 790",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/suplemento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 791",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/programa-de-beleza-integral/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 792",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/circuito-de-spa-premium-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 793",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/higueron-spa/pacote-de-experiencia-duo-25-separado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 794",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/manicure-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 795",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pro-dia-luminoso/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 796",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/design-de-sobrancelhas-com-pincas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 797",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/ritual-de-romance-sol-e-lua/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 798",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/ritual-for-two-100-spa-para-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 799",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/circuito-de-spa-massagem-e-cocktail-no-rooftop-com-vistas-especial-dia-da-mulher/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 800",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/massagem-relaxante-20-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 801",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cortar-sem-secar-senhora/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 802",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/chocolat-passion-spa-couple/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 803",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guipuzcoa/thalasso-villa-antilla/apaixonar-se-de-novo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 804",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/mascara-corporal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 805",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bcn-noite-massagem-15/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 806",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/express-marine-anti-fatigue/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 807",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-da-tensao-cervico-encefalica-e-da-lombalgia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 808",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/pequeno-almoco-buffet-incluido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 809",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/formacao-em-funcoes-4-ses/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 810",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/banho-de-cor-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 811",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/labio-superior/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 812",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/menorca/spa-faustino-gran/body-balance-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 813",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/circuito-termico-60min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 814",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/extra-profundo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 815",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/bracos-completos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 816",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/hb-beauty-party-private-spa-90-spa-privado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 817",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/depilacao-das-axilas-biquini/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 818",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/propina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 819",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/parceiro-mensal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 820",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/voucher-20-sessoes-ep/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 821",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/experiencia-mediterranica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 822",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-descontraturante-das-costas-com-castanhas-e-massagem-craniofacial-circuito-termal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 823",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/esfoliante-corporal-express/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 824",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/spa-bodyna-cordoba/banhos-romanos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 825",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spamassagem-2-pessoas-80e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 826",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-15-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 827",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/spa-sensations-lanzarote-park/ritual-de-assinatura-de-lanzarote/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 828",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-serenidade-black-friday-50-mins/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 829",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/lavar-e-secar-o-cabelo-medio-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 830",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/wellness-spam25jantar-tematico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 831",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/wellness-spam25-jantar-tematico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 832",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/fitness/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 833",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/massagem-de-aromaterapia-40/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 834",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/massagem-lomi-lomi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 835",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/massagem-de-boas-vindas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 836",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/urso-hotel-spa/massagem-de-relaxamento-60-min-individual-10/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 837",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/santuari-urban-spa/ritual-de-equilibrio-dos-chakras/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 838",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/wellnessweekend-2x1-acesso-matinal-a-wellness-zone-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 839",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/vale-ep-de-10-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 840",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/oasis-of-love-pack-casal-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 841",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/pacote-babymon-30-min-1-pessoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 842",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/bonus-10-sessoes-completas-de-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 843",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-de-fuga-anti-stress-de-4-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 844",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/fuga-de-4-dias-para-a-cura-anti-stress/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 845",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/maquilhagem-de-noiva-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 846",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-marina/mergulho-anti-stress-a-dois/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 847",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-attica21-vigo/circuito-termal-da-passagem-de-ano/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 848",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-tailandesa-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 849",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/bonus-de-descanso-e-bem-estar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 850",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/maquilhagem-de-noite-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 851",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/massagem-shiatsu-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 852",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/promocao-cabelo-saudavel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 853",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-com-lama-80min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 854",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/bau-de-casal-para-massagem-com-velas-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 855",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/experiencia-bio-spa-110-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 856",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/thalissispa/massagem-para-criancas-ate-12-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 857",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/soria/spa-castilla-termal-burgo-de-osma/shiatsu/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 858",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-de-relaxamento-30/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 859",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-tailandesa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 860",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/bodycare-the-volcan-lanzarote/pro-especial-mar-morto-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 861",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/eu-eu-mesmo-e-eu-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 862",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/summer-breeze-50-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 863",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-atrapalo-25-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 864",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/massagem-15min-pagamento-direto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 865",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/jacuzzi-e-sauna-privados-para-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 866",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-augas-santas-balneario-golf/circuito-termico-entrada-das-criancas-recp/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 867",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-albufeira/circuito-aqua-soul-criancas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 868",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/60-de-acesso-ao-spa-profes-especial-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 869",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/kobido-tonificacao-roulage-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 870",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-relax-para-2-natal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 871",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/bio-massagem-pre-natal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 872",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/especial-dia-dos-namorados-ritual-delights/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 873",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-com-pedras-quentes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 874",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/pacote-day-pass-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 875",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/tu-e-eu-massagem-para-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 876",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/spa-familiar-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 877",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/corte-e-penteado-de-cabelo-curto-de-senhora/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 878",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-the-cookbook/promocao-personalizada-ritual-anti-stress-60-min-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 879",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-the-cookbook/oferta-flash-ritual-anti-stress-60-min-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 880",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/shama-spa/circuito-de-agua-adulto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 881",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-das-axilas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 882",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guadalajara/spa-castilla-termal-brihuega/shiatsu/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 883",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/pacote-de-sensores/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 884",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/bodycare-the-volcan-lanzarote/pro-pedras-magicas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 885",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-massagem-50-experiencia-gastro-f-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 886",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/mascara/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 887",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/vela-ritual-acesso-ao-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 888",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-de-sobrancelhas-coloracao-de-sobrancelhas-coloracao-de-pestanas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 889",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/voltar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 890",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/personal-trainer-60min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 891",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-25-min-rececao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 892",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/embalagem-anti-manchas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 893",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-bangalore-india-rituais-do-mundo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 894",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/essencias-e-sentidos-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 895",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lleida/iberik-rocallaura-balneari/banheira-de-hidromassagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 896",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massagem-15min-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 897",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-albufeira/massagem-serenity/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 898",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/massagem-de-25-promocao-flash/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 899",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-da-cerveja-embalado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 900",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-massagem-25-pequeno-almoco-m-th/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 901",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/circuito-alegria-2px-vip-peito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 902",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/ritual-de-esplendor-radiante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 903",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/50-voucher-de-acesso-ao-spa-10-bilhetes-todos-os-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 904",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/acesso-ao-spa-50-10-bilhetes-todos-os-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 905",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/drenagem-linfatica-completo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 906",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/massagem-energizante-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 907",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/5-sessoes-de-ginasio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 908",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/massagem-30-experiencia-gastro/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 909",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/circuito-hidrotermal-massagem-de-25-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 910",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/massagem-15-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 911",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-ayurvedica-30-massagem-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 912",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/pernas-inteiras/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 913",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/spa-e-massagem-privados-30/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 914",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/spa-privado-2h/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 915",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/winter-ritual-110-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 916",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/bonus-experiencia-bodyna-massagem-de-aroma-citrico-30min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 917",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/circuito-spa-infantil/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 918",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/flash-desportivo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 919",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/bau-de-viagem-para-casais-das-mil-e-uma-noites-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 920",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bcn-evening-30-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 921",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavagem-e-corte-feminino/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 922",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/sessao-individual-ep/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 923",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/relaxante-completo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 924",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-massagem-25-experiencia-gastro-seg-a-qua/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 925",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/banho-de-parafina-de-15-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 926",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/adicionar-esmalte-semi-permanente/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 927",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-de-relaxamento-para-duas-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 928",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guadalajara/spa-castilla-termal-brihuega/acupunctura/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 929",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/prazeres-do-mediterraneo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 930",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/ritual-de-sono-cocoon/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 931",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cantabria/balneario-la-hermida/evasao-do-mar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 932",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/feliz-dia-pai/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 933",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/voucher-8-massagens-tailandesas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 934",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/penteado-classico-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 935",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/massagem-relaxante-20-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 936",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/iluminar-e-regenerar-o-seu-bonus-de-pele/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 937",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/degustacao-de-cerveja-com-harmonizacao-de-comida-e-vinho/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 938",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/fuerteventura/spa-sensations-iberostar-las-gaviotas/bioterapia-corporal-sistemica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 939",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/cuidados-rituais-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 940",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/aniversarios-em-salobre/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 941",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/4-aadd/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 942",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/embalagem-anti-manchas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 943",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/vitamina-c-radiance-ritual-rosto-e-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 944",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-craniana-recepcao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 945",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/massagem-com-cha-energetico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 946",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/vip-spa-privado-30-garrafa-de-cava/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 947",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-trimestral-seg-qui/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 948",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/sensorial-spa/circuito-spa-sensorial-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 949",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/os-prazeres-rituais-do-mediterraneo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 950",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/black-friday-2x1-massagem-50-bilhete-wellness/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 951",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/relaxar-e-gastro-ao-meio-dia-de-segunda-a-sexta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 952",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/circuito-motril-grupos-6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 953",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-augas-santas-balneario-golf/circuito-termico-entrada-m-f-recp/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 954",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-tratamento-facial-riche-de-jeunesse-tratamentos-faciais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 955",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/circuito-spa-tratamento-facial-com-vitamina-c/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 956",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/capricho-dos-deuses/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 957",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/kit-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 958",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/cervejaria-privada-duo-pack-dia-dos-namorados-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 959",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/mel-magico-acesso-ao-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 960",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-massagem-x2-pacotes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 961",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/tecido-profundo-90-min-deep-tisue-recepcao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 962",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/circuito-da-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 963",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/spa-privado-massagem-descontratante-organica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 964",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/kit-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 965",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/santuari-urban-spa/ritual-mediterranico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 966",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/se-e-de-alicante-este-desconto-e-para-si/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 967",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/creme-frio-pos-solar-marinho/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 968",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/ht-wellness-experiencia-matinal-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 969",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/pre-terapia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 970",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-craniofacial-massagem-energias-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 971",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-cranio-facial-massagem-energias-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 972",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/especial-she-rainha-do-egito-por-alqvimia-presente-bilhete-duplo-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 973",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/promocao-especial-para-hoteleiros/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 974",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spa-es-port/escapadela-para-dois/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 975",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/spa-entrada-do-circuito-de-agua-60-especial-para-profissionais-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 976",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/spa-brunch-no2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 977",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/descontos-30-3-bilhetes-duplos-de-acesso-ao-spa-50-total-6-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 978",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/unico-spa-finca-serena-mallorca/ritual-diamante-rosa-de-natura-bisse/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 979",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/magia-de-perchel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 980",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/circuito-de-spa-e-beleza-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 981",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/massagem-com-pedras-quentes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 982",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/gr-spa-50-cava-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 983",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/spa-hd-parque-cristobal/pack-massagem-25-spa-gastronomia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 984",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/pacote-de-prevencao-vital/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 985",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/vale-ep-de-10-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 986",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/circuito-spa-1-adulto-1-crianca-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 987",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/massagem-de-aromaterapia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 988",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/pequeno-almoco-buffet-com-marcacao-previa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 989",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/treino-pessoal-1-sessao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 990",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/fitness-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 991",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/jaen/thermas-palacio-de-ubeda/ritual-de-renascimento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 992",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-algarve/facial-hidratacao-profunda/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 993",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/depilacao-brasileira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 994",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/massagem-energizante-local/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 995",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-the-cookbook/verao-24-tempo-de-relaxamento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 996",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/despacio-beauty-center/tratamento-osteopatico-residentes-lanzarote/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 997",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/despacio-beauty-center/tratamento-de-osteopatia-residentes-em-lanzarote/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 998",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/tratamento-curto-de-alisamento-com-queratina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 999",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/vichy-detox/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1000",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/reserva-a-c/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1001",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavagem-corte-e-penteado-cabelo-curto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1002",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/esfoliacao-corporal-completa-envolvimento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1003",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/bodyna-experience-decontracturant-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1004",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-aromatica-completa-55/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1005",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/massagem-de-50-promocao-flash/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1006",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/massagem-de-tecidos-profundos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1007",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/signature-seventy-spa/cama-de-spa-de-30/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1008",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-com-pedras-quentes-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1009",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/treino-pessoal-8-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1010",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/voucher-de-6-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1011",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/nutrir-e-cuidar-da-sua-pele-no-verao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1012",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/voucher-4-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1013",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/spa-cocoon-privado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1014",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/circuito-sensacoes-grupo6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1015",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/aladinia-massagem-de-25-minutos-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1016",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/aladinia-25-minutos-de-massagem-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1017",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-de-chocolate/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1018",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/voucher-4-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1019",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-da-juventude-kobido-ritual-do-mundo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1020",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/ritual-de-chocoterapia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1021",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/lavar-e-secar-cabelos-muito-compridos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1022",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-shiatsu-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1023",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/tintura-de-pestanas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1024",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/europlayas-embalou-o-circuito-de-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1025",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-3-peeling-facial-m-c-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1026",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/dia-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1027",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-beatriz-toledo-auditorium/fevereiro-romantico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1028",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-2-pessoas-oferta/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1029",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/circuito-iberik-sabados-e-feriados-i-entrada-1710-horas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1030",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/cartao-de-oferta-para-empresas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1031",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/patilhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1032",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/momento-spa-e-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1033",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/suplemento-embalado-europlayas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1034",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/gravidez-relax-restore/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1035",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-com-pedras-quentes-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1036",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spadinner-pacotes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1037",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/pacote-especial-da-mae-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1038",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-4-para-ele-ou-para-ela/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1039",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/circuito-de-spa-para-criancas-dos-4-aos-17-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1040",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/aparar-a-barba/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1041",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/beer-spa/suplemento-embalado-europlayas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1042",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/la-vida-es-bella-spaalmoco-jantar-2-pessoas-7990-euros-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1043",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/mime-se-acesso-ao-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1044",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-attica21-vigo/circuito-de-spa-na-vespera-de-ano-novo-para-criancas-com-menos-de-12-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1045",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/parceiro-premium/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1046",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/casulo-balines/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1047",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/tratamento-de-hidratacao-profunda/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1048",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/circuito-spa-adulto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1049",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/aromatherapy-60-facial-xpress-30-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1050",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/reativar-e-tonificar-as-pernas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1051",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/manha-de-luxo-com-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1052",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/equilibrio-em-banguecoque-banho-de-pes-esfoliacao-corporal-mascara-corporal-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1053",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/aladinia-50-minutos-de-massagem-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1054",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/esfoliante-corporal-express/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1055",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/acesso-de-30-ao-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1056",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/massage-cure-25/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1057",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/acesso-wellness-myevjf-50-privatizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1058",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/matar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1059",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/janeiro-em-forma/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1060",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/promocao-especial-para-os-amantes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1061",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-de-aromaterapia-30-massagens-de-energia-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1062",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/europlayas-embalou-o-circuito-de-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1063",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/atrapalo-25-minutos-de-massagem-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1064",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/happy-hour-b-calm-relax-30-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1065",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/circuito-spa-adulto-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1066",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spa-bodyna-maricel/ritual-do-grande-magrebe/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1067",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/oleo-essencial-tailandes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1068",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-pazo-los-escudos/eternal-youth-facial-ritual-laboratorio-alqvimia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1069",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/momento-spa-ritual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1070",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/propina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1071",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-anti-stress-blancafort/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1072",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/bonus-mensal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1073",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/leiria/termas-da-piedade-by-your-hotel-spa-alcobaca/banhos-termais-20-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1074",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guipuzcoa/thalasso-villa-antilla/menu-especial-do-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1075",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/acesso-ao-spa-naturista-90-1-taca-de-champanhe/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1076",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/caixa-privadax3-viagem-ao-oriente-aroma-de-oud-ambar-de-morjana/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1077",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/dia-dos-namorados-na-be-aloe-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1078",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/massagem-modeladora-da-comfort-zone/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1079",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/pacote-de-25-minutos-de-massagem-com-chocolate/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1080",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/higueron-spa/pack-experiencia-duo-50-separado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1081",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/oasis-de-serenidade/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1082",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/ritual-calmante-flotarium-20/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1083",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/circuitos-aladinia-2-pessoas-4990e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1084",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/pequeno-almoco-buffet-incluido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1085",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/voucher-10-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1086",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/peeling-polinesio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1087",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/gloria-palace-san-agustin-thalasso/piscina-fitness/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1088",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pro-especial-mar-morto-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1089",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/tailandes-balines/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1090",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/las-dunas-spa/massagem-a-quatro-maos-harmonia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1091",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guipuzcoa/thalasso-villa-antilla/escapadela-no-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1092",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/suplemento-embalado-europlayas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1093",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/voucher-5-ep-sessions/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1094",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/get-ready-for-summer-100-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1095",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/couple-special-alegria-circuit-tea-service-couple/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1096",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/sublime-skin-da-comfort-zone/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1097",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/circuito-spa-adulto-2x1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1098",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-completa-da-perna/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1099",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-total-da-perna/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1100",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/luxo-ritual-luxo-nusa-dua/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1101",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/degustacao-de-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1102",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/piscina-hidrotermal-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1103",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/diferenca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1104",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/tratamento-de-hidratacao-profunda-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1105",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/massagem-pos-mama/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1106",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-anthelia/spa-e-pequeno-almoco/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1107",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spa-sercotel-odeon-hotel/pacote-premium-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1108",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/massagem-de-25-promocao-flash/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1109",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/massagem-luna-de-valencia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1110",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alava/silken-villa-laguardia-hotel/momento-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1111",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/voucher-10-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1112",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-cadiz/grupo-do-circuito-das-termas-da-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1113",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/3-aadd/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1114",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/reflexo-do-pe/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1115",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-anti-stress-blancafort-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1116",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/massagem-relaxante-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1117",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/circuitos-dakotabox-2-pessoas-3990e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1118",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massagem-cervejeira-degustacao-de-1-cerveja-especial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1119",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massagem-de-cerveja-degustacao-de-1-cerveja-especial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1120",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-costas-e-torax/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1121",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-das-costas-e-do-peito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1122",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-da-futura-mama-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1123",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/voucher-circuito-spa-7-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1124",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/voucher-circuito-spa-6-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1125",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/pack-de-acido-hialuronico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1126",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/voucher-relax-gin/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1127",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-massagem-50-pequeno-almoco-f-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1128",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/spa-familiar-2x1-mais-20-de-desconto-nos-tratamentos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1129",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/oferta-bodyna-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1130",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-anti-stress-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1131",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/desintoxicacao-de-bambu-50min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1132",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-massagem-50-m-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1133",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/circuito-iberik-sabados-e-feriados-i-entrada-1200-horas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1134",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/chocolat-passion-spa-couple/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1135",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/vale-ep-de-10-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1136",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/pack-de-acido-hialuronico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1137",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/cartao-presente-premium/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1138",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/ritual-da-brisa-de-ganesha/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1139",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/respiracao-de-bora-bora/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1140",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/circuito-spa-junior-4-17-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1141",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-cliente-alojado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1142",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-7-alvo-da-silhueta/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1143",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/experiencia-ativa-bom-dia-sessao-crossfit-thalassa-buffet-de-pequeno-almoco-90-minutos-de-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1144",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/nutrir-e-cuidar-da-sua-pele-para-a-nova-estacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1145",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/pedicura/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1146",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/entrada-do-spa-hospede-do-hotel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1147",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/ritual-do-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1148",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/manicure-permanente/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1149",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/tratamento-de-alisamento-com-queratina-medio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1150",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/massagem-champi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1151",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/pacote-plus-massagem-circuito-spa-almoco/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1152",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-la-quinta/programa-anti-stress-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1153",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dor-dia-1-massagem-anti-dor-ultra-sons/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1154",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/aroma-tailandes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1155",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/piscina-hidrotermal-cocktail-atrapalo-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1156",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/pack-hidr-oxigenio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1157",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/casulo-de-casal-1-pessoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1158",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/voucher-de-4-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1159",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/aloe-aftersun-costas-couple/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1160",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-vale-de-id-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1161",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/spa-balneario/pt/spas/acores/musgo-spa/musgo-bliss-90-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1162",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/musgo-bliss-esfoliacao-massagem-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1163",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-especial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1164",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-massagem-pacotes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1165",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/bonus-10-sessoes-completas-de-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1166",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/aqua-harmony-recepcao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1167",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/intervalo-de-15-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1168",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/voucher-spa-50-semanal-7-dias-alldays/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1169",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/massagem-ao-por-do-sol-no-egito/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1170",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/esfoliante-corporal-de-canela-e-gengibre/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1171",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/signature-spa-beach/massagem-de-relaxamento-50min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1172",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/pacote-de-massagem-de-10-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1173",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-de-bem-estar-para-2-natais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1174",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/curas-da-massagem-anti-celulitica-curas-da-massagem-anti-celulitica-curas-da-massagem-anti-celulitica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1175",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/circuito-beer-spa-com-catering-de-tapas-frias-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1176",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/viagem-ao-eden-tropical/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1177",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-bienestar-moana/ritual-de-espera-doce-clube-termal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1178",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/spa-bodyna-madrid/massagem-balinesa-35/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1179",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-de-aromaterapia-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1180",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/viagem-magica-pela-rota-da-seda/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1181",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/massagem-10-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1182",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/depilacao-12-pernas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1183",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/parceiro-premium/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1184",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/venda-20-de-desconto-2-bilhetes-duplos-de-acesso-ao-spa-50-total-4-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1185",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playa-marina-spa-hotel/buffet-de-spa-adulto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1186",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pro-pedras-magicas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1187",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/depilacao-completa-das-pernas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1188",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/senator-cadiz-spa-hotel/voucher-pessoal-5-bilhetes-para-o-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1189",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/pacote-de-prevencao-vital/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1190",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/promocao-especial-para-hoteleiros/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1191",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-para-golfistas-40-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1192",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/o-essencial-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1193",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/voucher-de-6-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1194",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/parceiro-mensal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1195",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/spa-inagua/circuito-spa-hydra-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1196",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/circuito-da-praia-de-granada-alojamento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1197",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/passe-de-acesso-ao-spa-50-10-bilhetes-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1198",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-fanabe/spa-privado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1199",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/tribunal-de-menores-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1200",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/queratina-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1201",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/voucher-de-4-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1202",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/circuito-spa-2-adultos-1-crianca-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1203",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/entrada-despedida-de-soltera/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1204",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/circuitos-wonderbox-2-pessoas-4990e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1205",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-lomi-lomi-havaiana/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1206",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/soria/spa-castilla-termal-burgo-de-osma/absentencao-4-h/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1207",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/momento-spa-e-massagem-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1208",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/voucher-10-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1209",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavagem-e-penteado-cabelos-compridos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1210",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dor-dia-2-massagem-tailandesa-reflexologia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1211",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/sh-holistico-e-luxo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1212",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/voucher-de-6-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1213",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/chocolate-wrap/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1214",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/esfoliante-corporal-zen/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1215",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/descamacao-do-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1216",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/vip-private-wellness-30-garrafa-de-cava/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1217",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bcn-noite-spa-90-cava-pacote-de-pequeno-almoco/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1218",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/ritual-da-pedra-de-sal-da-comfort-zone/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1219",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/veu-de-colagenio-da-om/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1220",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-25-min-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1221",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-de-beleza-holistica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1222",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/parceiro-formador/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1223",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/cartao-de-oferta-para-empresas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1224",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/massagem-10-min-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1225",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/servico-complementar-de-cabeleireiro-ou-maquilhagem-no-quarto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1226",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/servico-suplementar-de-cabelo-ou-maquilhagem-no-quarto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1227",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/objetivo-de-cura-da-silhueta/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1228",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/silhueta-objetivo-cura/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1229",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/pacote-day-pass-deluxe/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1230",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/combo-3-sessoes-ama-o-teu-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1231",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-com-lama-60min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1232",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/sublime-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1233",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/massagem-de-casal-sup/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1234",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/sauna/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1235",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/momento-spa-ritual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1236",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/voucher-4-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1237",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/vale-de-10-bilhetes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1238",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/circuito-termico-1h30min-preparacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1239",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/massagem-sueca-descontratante-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1240",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/at-massagem-25-m-th/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1241",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/destaques/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1242",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/all-at-al-hb-ht-pequeno-almoco-buffet/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1243",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/mascara-facial-de-cura/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1244",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/pacote-de-sensores/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1245",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/hard-rock-spa-marbella/zona-de-agua-pequeno-almoco/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1246",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/diamante-bem-viver-de-natura-bisse-90/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1247",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/massagem-15-min-pagamento-direto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1248",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-local/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1249",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/promocao-especial-para-hoteleiros/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1250",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/ritual-de-esplendor-radiante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1251",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/circuito-de-spa-e-beleza-casal-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1252",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/depilacao-das-costas-dos-homens/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1253",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/bae-barry-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1254",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/ritual-chakra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1255",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/piscina-interior-com-jactos-lombares-e-cervicais-weekendesk/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1256",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/formacao-60-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1257",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/massada-men-pack/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1258",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/mascara-corporal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1259",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-trimestral-todos-os-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1260",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/codorniu-75cl/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1261",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/hb-massagem-50-experiencia-gastro-seg-sab/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1262",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/um-paraiso-tranquilo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1263",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/circuito-spa-adulto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1264",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/tecido-profundo-80min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1265",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-deluxe-2-massagens-de-15-minutos-rececao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1266",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-beatriz-toledo-auditorium/mascara-de-cacau-beauty-party/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1267",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/spa-romantico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1268",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/massagem-relaxante-20-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1269",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-anual-todos-os-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1270",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/body-sculpt-anticelular/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1271",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/circuito-spa-pequeno-almoco-buffet/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1272",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/circuito-de-spa-de-cerveja-na-sala-comum-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1273",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-manicure-expresso-tratamentos-para-maos-e-pes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1274",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/ritual-queen-of-al-andalus-para-uma-pessoa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1275",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/pedicura-estetica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1276",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/experiencia-de-spa-para-2-natais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1277",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/oferta-de-circuito-de-segunda-a-quinta-feira/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1278",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/entr-casal-de-persn-8-sess/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1279",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/hb-spa-50-cava-l-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1280",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/pincar-as-sobrancelhas-apenas-com-tratamento-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1281",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/massagem-craniana-as-costas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1282",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/ritual-therapy-o2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1283",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/doze-amor-40m-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1284",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/extras-30-promocionais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1285",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/summer-breeza-50-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1286",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/inner-flow/promocao-do-mes-da-mulher-inner-goddes-90/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1287",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/voucher-4-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1288",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/agencias-spa-entrada-90-privatizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1289",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/pernas-perfeitas-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1290",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spai-son-armadans/brunch-e-circuito-spa-31/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1291",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/tratamento-facial-hydra-olhos-sothys/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1292",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/momento-be-aloe-massagem-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1293",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/massagem-de-exclusividade-completa-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1294",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/cervejaria-privada-duo-pack-dia-dos-namorados-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1295",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lleida/balneario-caldes-de-boi/massagem-neurossedativa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1296",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/massagem-local-para-duas-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1297",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/reino-da-hungria/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1298",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/notas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1299",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/la-vida-es-bella-spaalmoco-jantar-2-pessoas-7990-euros/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1300",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/all-at-al-hb-ht-pequeno-almoco-buffet/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1301",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/tratamento-longo-de-alisamento-com-queratina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1302",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massagem-cerveja-completa-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1303",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-en-zahara/exclusivity-beer-massage-25min-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1304",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/almoco-no-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1305",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/ritual-de-massagem-indiano/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1306",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/voucher-de-6-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1307",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/bio-massagem-tailandesa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1308",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/wellness-access-myevjf-90-nao-privatizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1309",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/circuito-termal-sextas-sabados-domingos-e-feriados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1310",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/circuito-de-praia-granada-weekendesk/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1311",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/soria/spa-castilla-termal-burgo-de-osma/relaxamento-total-quatro-maos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1312",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/agencias-spa-access-90-privatizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1313",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-attica21-vigo/circuito-de-spa-menos-de-12-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1314",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/hidraforce-source-marine/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1315",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/spa-e-jantar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1316",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-boutique-estimar-valencia-4one/spa-e-massagem-privados-50/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1317",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/esfoliacao-fresca-com-aloe-vera-flor-de-tiare-e-bambu-circuito-termal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1318",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/wellness-spa-jantar-tematico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1319",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/caverna-de-sal-rececao-das-criancas-40min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1320",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/por-do-sol-no-oceano-x2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1321",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/oferta-de-massagem-15-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1322",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/por-do-sol-no-oceano-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1323",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/massagem-personalizada-e-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1324",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/ritual-circuito-do-esplendor-radiante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1325",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/circuito-radiant-splendour-ritual-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1326",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/black-friday-2x1-massagem-50-bilhete-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1327",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/unico-spa-finca-serena-mallorca/massagem-de-bem-estar-de-30/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1328",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/circuitos-dakotabox-2-pessoas-4990e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1329",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/pacote-de-amor-cintilante-para-casais-no-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1330",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/essencias-e-sentidos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1331",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-turca-com-sabao-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1332",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/black-friday-circuito-thalasso-3h-com-jacuzzi-vista-mar-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1333",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/especial-natal-love-para-2-pessoas-espaco-wellness-massagem-taca-de-cava-e-bau-de-alqvimia-presente-para-si/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1334",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/gpl/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1335",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/reforco-de-colagenio-do-cavalheiro/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1336",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/reflexologia-podal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1337",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/massada-men-pack/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1338",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/oporto/solverde-spa-wellness-center/ritual-anti-stress-atlantico-relaxante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1339",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/dia-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1340",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/fim-de-semana-25-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1341",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/pacote-de-cura-de-relaxamento-de-2-dias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1342",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/creme-hidratante-intenso/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1343",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/massagem-15-individual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1344",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/momento-spa-e-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1345",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/circuito-spa-privado-jantar-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1346",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagens-relaxantes-20-massagens-energias-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1347",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/sauna-banho-turco-jacuzzi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1348",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/jantar-no-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1349",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/drenagem-linfatica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1350",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massagem-de-cerveja-25-prova-de-cerveja-1-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1351",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/massagem-10-min-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1352",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/circuito-iberik-sabados-e-feriados-i-entrada-as-1530-horas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1353",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/bau-do-atelier-do-amor-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1354",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/manicure-jovem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1355",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/hard-rock-spa-marbella/zona-aquatica-jantar-buffet/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1356",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/circuito-spa-e-beleza-alojamento-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1357",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/ritual-de-esplendor-radiante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1358",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/all-at-al-gr-suplemento-spa-pack-50-f-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1359",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/remocao-de-esmalte-semi-permanente/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1360",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/banos-arabes-palacio-comares/oasis-of-love-pack-casal-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1361",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/smartbox-2-pessoas-3990/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1362",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/spa-privado-pequeno-almoco-massagem-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1363",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/agencias-bilhete-spa-50-privatizado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1364",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/peeling-corporal-de-coco-hidratacao-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1365",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/massagem-55-min-alojamento-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1366",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/beer-spa-madrid/cervejaria-privada-duo-pack-dia-dos-namorados-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1367",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sunlight-bahia-principe-san-felipe/tratamento-facial-supremo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1368",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/massagem-corporal-pernas-cansadas-20-massagem-energias-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1369",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/sessao-de-crossfit-na-box-de-thalassa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1370",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/bonus-10-sessoes-completas-de-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1371",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/este-natal-ofereca-a-prenda-do-bem-estar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1372",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/spa-familiar-2x1-mais-20-de-desconto-nos-tratamentos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1373",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spa-sercotel-odeon-hotel/jacuzzi-privado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1374",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/vip-spa-privado-30-garrafa-de-cava/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1375",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-albufeira/massagem-express/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1376",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/mecas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1377",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/immerso-spa/escapadinha-immerso-massagem-jacuzzi-privado-spa-estadia-para-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1378",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/viagem-ao-eden-tropical-individuais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1379",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/programa-especial-para-pernas-cansadas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1380",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-esfoliante-corporal-aromatico-picante-esfoliante-corporal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1381",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/spa-deluxe-massagem-15-min-rececao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1382",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/bcn-night-spa-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1383",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/voucher-atrapalo-acesso-ao-spa-menu-de-3-pratos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1384",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-do-labio-superior-e-do-queixo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1385",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/lavar-e-secar-o-cabelo-curto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1386",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/torax-abdomen/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1387",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/pacote-de-mimos-esfoliacao-massagem-as-maos-massagem-com-balsamo-30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1388",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/senza-vi-penteado-curto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1389",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/massagem-relaxante-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1390",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/relaxar-e-desfrutar-pacotes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1391",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-de-desintoxicacao-dia-4-massagem-pinda-sweda/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1392",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/promocao-dia-dos-namorados-circuito-termal-60-min-massagem-oriental-30-min-pernas-e-costas-em-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1393",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/2aadd/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1394",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/bilhete-de-crianca-criancas-dos-2-aos-11-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1395",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-bodyna-palau-de-mar-valencia/acesso-ao-bodyna-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1396",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-acupunctura-70-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1397",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/azure-wellness-spa/happy-day-with-friends/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1398",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/pacote-de-casa-de-banho/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1399",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/benicaldea-thai-massage-spa/spa-20h/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1400",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-6-dia-de-spa-especial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1401",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/babylights-senza-vi/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1402",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-balneario-de-guitiriz-golf/circuito-iberik-sabados-e-feriados-i-entrada-1850-horas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1403",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/voucher-de-4-sessoes-de-massagem-completa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1404",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/toledo/spa-domus-aurea/encerramento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1405",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/europlayas-embalou-o-circuito-de-cerveja/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1406",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/maquilhagem-para-eventos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1407",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/depilacao-da-linha-do-biquini/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1408",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valladolid/spa-castilla-termal-olmedo/relaxamento-total-quatro-maos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1409",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/massagem-cocoon-personalizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1410",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/massagem-relaxante-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1411",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/formacao-30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1412",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/senza-vi-penteado-medio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1413",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/especial-natal-love-para-2-pessoas-spa-massagem-taca-de-cava-e-bau-de-alqvimia-presente-para-si/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1414",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/limpeza-extra-do-rosto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1415",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/vale-ep-de-10-sessoes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1416",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-desportiva-completa-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1417",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/maquilhagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1418",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/massagem-de-relaxamento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1419",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/beer-spa-cadiz/massagem-15min-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1420",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/ht-massagem-30-experiencia-matinal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1421",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/kintsugi-spa/descontratacao-da-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1422",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/shiatsu/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1423",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pro-cuidados-de-topo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1424",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/circuito-de-spa-organizacao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1425",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/hard-rock-spa-marbella/tratamento-de-luxo-para-2-pessoas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1426",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-la-residencia-tarifa/oferta-de-circuito-spa-para-2-plataformas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1427",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/cartao-de-oferta-para-empresas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1428",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-a-medida-60min-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1429",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/spa-calm-luxury-premium/circuito-spa-privado-lovers-com-bolo-de-queijo-cava-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1430",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/circuitos-wonderbox-2-pessoas-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1431",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/enchimento-marinho-hyalu-procollagen/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1432",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/ritual-da-brisa-de-ganesha/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1433",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/mascara-facial-em-alta/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1434",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/fuerteventura/spa-sensations-iberostar-las-gaviotas/bioterapia-facial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1435",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/entrada-pessoal-para-casais-1-sessao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1436",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massagem-15-min-pagamento-direto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1437",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/depilacao-das-axilas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1438",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/10-min-de-massagem-extra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1439",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/10-minutos-de-massagem-extra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1440",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/cartao-presente-premium/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1441",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-ayurvedica-india-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1442",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spa-es-port/arrefecer-o-rosto-e-os-pes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1443",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/circuito-duch-groups-6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1444",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/circuito-duch-grupos-6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1445",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/massada-men-pack/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1446",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/spa-privado-massagem-de-relaxamento-vegan/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1447",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-atrapalo-50-minutos-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1448",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/sauna-30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1449",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/60-massagem-muscular/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1450",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/tradicional-tailandesa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1451",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/bonus-10-sessoes-completas-de-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1452",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/agencias-spa-access-50-privatizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1453",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/voucher-6-sessoes-de-massagem-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1454",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/spa-attica-21-villalba/beleza-spa-tratamento-20-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1455",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/senator-marbella-spa-hotel/viagem-a-rota-das-delicias/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1456",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-augas-santas-balneario-golf/massagem-com-oleo-de-chocolate/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1457",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/full-vitality-spa-sierra-nevada/sauna-e-jacuzzi-1-pessoa-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1458",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/diferenca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1459",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/badajoz/spa-adealba/spa-privado-pequeno-almoco-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1460",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/beer-spa-tenerife/pacote-de-beleza/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1461",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bodycare-gara-suites-golf-spa/pro-melhor-momento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1462",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/beer-spa-madrid/massagem-holistica-com-oleo-de-cerveja-parcial/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1463",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/cocoon-sport-pro-da-therabody/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1464",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-escapadela-romantica-para-2-pessoas30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1465",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-escapadela-romantica-2-pessoas30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1466",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-massagem-ayurvedica-45-massagem-energias-da-terra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1467",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/at-spa-50-cava-l-j/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1468",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/al-massagem-25-m-th/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1469",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/beer-spa-islantilla/circuito-de-spa-de-cerveja-na-sala-comum-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1470",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/smartbox-2-pessoas-4990e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1471",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/spa-taca-de-cava-promocao-2x1-m-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1472",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/fuerteventura/spa-sensations-iberostar-las-gaviotas/sprunch/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1473",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/massagem-facial-ko-bi-do-25min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1474",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/banguecoque-surpreendente-banho-de-pes-jacuzzi-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1475",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/oferta-3-peeling-corporal-mascara-corporal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1476",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/bio-reflexologia-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1477",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/cafe-ritual/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1478",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/tintura-para-cabelo-curto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1479",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/15-min-de-massagem-alojamento-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1480",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/m-production-escapade-100e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1481",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/pintura-de-unhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1482",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/santuari-urban-spa/ritual-pedras-semipreciosas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1483",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/circuito-spa-crianca/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1484",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-wellness-50-gastro-experience-50-f-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1485",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/al-wellness-50-gastro-experience-50-f-f/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1486",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-meia-perna/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1487",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/voucher-10-passes-para-a-piscina-exterior/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1488",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lisboa/immerso-spa/escapadinha-immerso-massagem-spa-almoco-estadia-para-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1489",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/basalto-com-amor-express-facial-by-flor-azorica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1490",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/programa-de-revitalizacao-da-energia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1491",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/teste-de-penteado-de-noiva/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1492",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-costa-adeje/bio-massagem-com-pedras-quentes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1493",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/escapadela-romantica-privada-com-almoco-ou-jantar-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1494",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/spa-sensations-lanzarote-park/hrs-lifting-e-anti-envelhecimento-ao-mais-alto-nivel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1495",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/fitness-reduzido/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1496",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spamassagem-100e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1497",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/spa-mas-de-torrent/m-production-dia-de-spa-132e/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1498",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-privado-para-2-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1499",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/hsr-lifting-e-anti-envelhecimento-ao-mais-alto-nivel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1500",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/faca-uma-pausa-nas-suas-ferias-desfrute-de-um-spa-de-50min-com-uma-massagem-de-25min-e-um-copo-de-cava-ou-sumo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1501",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-dia-3-shiro-cabeca-pad-abhyanga-pernas-e-pes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1502",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/vip-ritual-de-corpo-inteiro-serenity-e-spa-privado-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1503",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/teste-de-maquilhagem-de-noiva/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1504",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lugo/iberik-augas-santas-balneario-golf/iberik-massagem-termica-pqt/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1505",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/spa-bodyna-maricel/experiencia-acolhedora-opcao-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1506",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/esfoliacao-corporal-completa-envolvimento/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1507",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/circuito-termal-de-natal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1508",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/experiencia-harmonia-90-minutos-de-circuito-spa-ritual-facial-com-tratamento-especifico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1509",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/granada-five-senses-spa/circuito-vitalidade-grupos-6px/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1510",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/massagem-com-aromaterapia-30min-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1511",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/la-laguna-spa-golf/contorno-de-olhos-cryo-sothys/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1512",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/experiencia-thalasso-3h-menu-canto-da-natureza-x-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1513",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/bio-spa-victoria/senza-vi-penteado-alto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1514",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/lemax-wellness-club-wellington/circuito-spa-60-massagem-45/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1515",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/cartao-presente-premium/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1516",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/retirado-colour-gel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1517",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cortar-e-pentear-os-cabelos-compridos-da-lady/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1518",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/circuito-spa-senior/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1519",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-das-axilas-e-da-linha-do-biquini/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1520",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/huelva/playacartaya-spa-hotel/buffet-de-spa-criancas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1521",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/oporto/neya-day-spa-porto/neya-duo-massage/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1522",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/senator-banus-spa-hotel/circuito-de-spa-para-criancas-4-a-14-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1523",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/depilacao-meia-perna/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1524",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/tratamento-facial-de-natal-vital/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1525",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-the-cookbook/programa-zen/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1526",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/massagem-relaxante-i-30-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1527",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/acesso-ao-spa-tour/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1528",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-sensations-iberostar-sabila/calcas-frigi-legs-pernas-leves/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1529",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/tratamento-desportivo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1530",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/sens-spa/pack-rose-cerveja-de-hidromel/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1531",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/azz-valencia-congress-spa/duche-vichy/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1532",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/spa-balneario/pt/spas/acores/musgo-spa/spa-para-2-adultos-e-1-crianca-7-a-13-anos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1533",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/pequeno-almoco-buffet-circuito-spa-massagem-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1534",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/massagem-terapeutica/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1535",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cordoba/vaho-spa/spa-relax-natal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1536",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/hb-serenidade-black-friday-50-mins/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1537",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavagem-e-corte-homens/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1538",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-60-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1539",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/guipuzcoa/thalasso-villa-antilla/doce-dia-dos-namorados/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1540",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/pontevedra/spa-attica21-vigo/experiencia-de-spa-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1541",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/experiencia-energia-relax-sessao-crossfit-thalassa-circuito-spa-90min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1542",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/spa-privado/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1543",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/malaga/hard-rock-spa-marbella/zona-aquatica-legendary-burger-experience/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1544",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-playa-granada/jantar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1545",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/al-spa-50cava-f-r/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1546",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/spa-hotel-laguna-nivaria/escapadela-romantica-privada-para-2-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1547",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/beer-spa/cervejaria-privada-duo-pack-dia-dos-namorados-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1548",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/massagem-lomi-lomi-havai-casal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1549",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/aqua-club-termal/massagem-40-min-rececao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1550",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/senator-caleia-talayot-spa/ritual-hidraluronico/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1551",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/tintura-de-pestanas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1552",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/corallium-lopesan-villa-del-conde/piscina-com-vista-para-o-mar-30min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1553",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/sonho-do-pe-mao/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1554",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/agencias-area-wellness-50-privatizada/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1555",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-de-banho-cleopatra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1556",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-de-banho-de-cleopatra/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1557",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/mae-tu-mereces-circuito-de-aguas-massagem-circuito-de-aguas-de-acompanhamento-gratis/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1558",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-esfoliacao-com-savon-noir-beldi-esfoliante-corporal/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1559",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-de-quioto-japao-rituais-do-mundo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1560",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/wellness-center-natural-spa/spalopia-com-circuito-de-relaxamento-e-massagem-para-2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1561",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/preparacao-da-cabina/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1562",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/girona/cocoon-spa-lazure/cocoon-reconnect-80-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1563",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/ritual-radiance-c/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1564",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/pacote-de-sensores/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1565",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/biquini-depilacao-com-cera-para-biquini/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1566",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-tratamento-facial-fleurs-de-bali-tratamentos-faciais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1567",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/acores/musgo-spa/reflexologia-pes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1568",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/valencia/senzia-gandia-spa-wellness/terapia-ritual-o2/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1569",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/massagem-de-tecidos-profundos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1570",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/aladinia-massagem-de-40-minutos/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1571",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacao-das-costas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1572",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/massada-men-pack/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1573",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/madrid/serena-spa-melia-madrid-princesa/massagem-experience-profes/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1574",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/bangkok-healthy-spa/calor-de-ervas-tailandesas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1575",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/doce-love-casais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1576",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-diamante-beach/circuito-spa-2-adultos-2-criancas-60/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1577",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/playasol-spa-hotel/circuito-standard-spa-teen/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1578",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/pacote-de-massagem-55-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1579",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/pacote-de-55-minutos-de-massagem/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1580",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/signature-spa-beach/massagem-do-amor-50min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1581",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/verao-de-relaxamento-massagem-50-solario-com-copo-de-cava-30-2x1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1582",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-el-cortijo/pack-hidr-oxigenio/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1583",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/controlo-de-peso-e-ou-volume-do-cura/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1584",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cura-de-controlo-de-peso-e-ou-volume/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1585",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/mallorca/can-bordoy-spa/spa-privado-massagem-facial-e-corporal-descontraturante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1586",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/murcia/senator-mar-menor/massagem-relaxante-50-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1587",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/funcao-de-formacao-8-ses/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1588",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-5-envolvimento-de-algas-massagem-circulatoria/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1589",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/leon/eurostars-via-de-la-plata/circuito-de-spa-60-min/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1590",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lleida/iberik-rocallaura-balneari/massagem-termal-iberik/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1591",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/beer-spa-alicante/saco-de-esfoliacao-xl/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1592",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/lopesan-costa-meloneras/massagem-tailandesa-80/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1593",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-the-cookbook/verao-24-hora-do-bem-estar/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1594",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/15-minutos-adicionais/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1595",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/faro/soul-spa-by-jupiter-marina/facial-hidratacao-profunda/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1596",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/lanzarote/spa-sensations-lanzarote-park/prodigio-do-oceano/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1597",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/almeria/zimbali-playa-spa-hotel/massagem-corporal-com-mousse-de-chocolate/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1598",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-bodyna-granada/especial-dia-das-maes-2-pax/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1599",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spa-sercotel-odeon-hotel/circuito-spa-gastronomia/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1600",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/circuito-spa-infantil-4-a-15-anos-1/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1601",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/hipotels-barrosa-palace-spa/cura-para-descascar-o-corpo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1602",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavagem-e-penteado-cabelo-curto/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1603",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/brazilian-ritual-coffee-cream-adelgacante-e-refirmante/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1604",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/alicante/spa-daniya-denia/childrens-rice-spa-relax-experience-criancas-dos-5-aos-11-anos-menu-de-arroz-incl-agua-90-min-de-circuito-de-spa/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1605",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/cadiz/spa-tarifa/pacote-de-prevencao-vital/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1606",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/oporto/neya-day-spa-porto/douro-essence-massage-90/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1607",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/callao-spa-center/terapeutico-completo/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1608",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/granada/spa-catalonia-granada/cartao-presente-premium/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1609",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/gran-canaria/be-aloe-wellness-salobre-golf-hotel/tintura-para-sobrancelhas/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.name ?? "Redirection landing: 1610",
    path: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.path ?? "/pt/spas/a-coruna/spa-sercotel-odeon-hotel/capricho-de-chocolate/",
    props: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.props ?? false,
    meta: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta || {},
    alias: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.alias || [],
    redirect: Redirection_46pageufXT9SmgedwXAGPNWHcz2kB55OXE181AUnsdufl8sQQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-7178cf7a-ef88-4844-8b17-c70a4e765ad0/b2c-build/core/pages/redirection/Redirection.page.vue")
  }
]